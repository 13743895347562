import logo from './UTUTICONPLEB.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <br/><br/>
        <p>
          U-Tutors HomePage <br/> We will be updating this page soon to include more substantive information.
        </p>
        
      </header>
    </div>
  );
}

export default App;
